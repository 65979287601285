import React, { FC, useEffect, useMemo } from 'react';
import { DesktopWidget } from './desktop/DesktopWidget';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from './apollo/client';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { StyleParamsCSSVariables } from '../old/components/style-params-css-variables/StyleParamsCSSVariables';
import { MobileWidget } from './mobile/MobileWidget';
import { FetchAdapter } from './worker/fetch/fetchAdapter';
import { useChannelId } from './hooks';
import { Channel } from './common/components/Channel/Channel';
import { SingleVideo } from './common/components/SingleVideo/SingleVideo';
import { WorkerContext } from './hooks/useWorker';
import { Direction } from './common/components/Direction/Direction';
import { ControllerProps } from './controller';
import { SiteInfoProvider } from './common/providers/SiteInfoProvider';
import { PreviewPrompt } from './common/components/PreviewPrompt/PreviewPrompt';
import { PreviewProvider } from './common/providers/PreviewProvider';
import { BiReadinessProvider } from './common/providers/BiReadinessProvider';

export type NewWidgetProps = WidgetProps<ControllerProps>;

const DeviceWidget: FC = () => {
  const { isMobile } = useEnvironment();

  return isMobile ? <MobileWidget /> : <DesktopWidget />;
};

const ChannelWidget: FC = () => (
  <Channel>
    <DeviceWidget />
  </Channel>
);

const VideoWidget: FC = () => (
  <SingleVideo>
    <DeviceWidget />
  </SingleVideo>
);

const ChannelOrVideoWidget: FC = () => {
  const channelId = useChannelId();
  return channelId ? <ChannelWidget /> : <VideoWidget />;
};

export const NewWidget: FC<NewWidgetProps> = (props) => {
  const {
    instance,
    initialCacheData,
    gqlHost,
    doPost,
    responses,
    removeResponse,
    modals,
    purchases,
    pricingPlans,
    user,
    siteInfo,
  } = props;
  const { finishedPurchasesCount } = purchases;
  const fetchAdapter = useMemo(
    () => new FetchAdapter(instance, doPost, removeResponse),
    [instance, doPost, removeResponse],
  );

  const client = useMemo(
    () =>
      createApolloClient(
        gqlHost,
        instance,
        fetchAdapter,
        finishedPurchasesCount > 0 ? undefined : initialCacheData, // reload on purchase
      ),
    [instance, initialCacheData, gqlHost, fetchAdapter, finishedPurchasesCount],
  );

  useEffect(() => {
    fetchAdapter.setResponses(responses);
  }, [fetchAdapter, responses]);

  return (
    <Direction>
      <ApolloProvider client={client}>
        <WorkerContext.Provider
          value={{ modals, purchases, pricingPlans, user }}
        >
          <SiteInfoProvider value={siteInfo}>
            <BiReadinessProvider>
              <PreviewProvider>
                <StyleParamsCSSVariables>
                  {/* if not this div it sometimes renders second empty div of classic channel, when vodNoCache param is present*/}
                  <div data-hook="widget-container">
                    <ChannelOrVideoWidget />
                  </div>
                  <PreviewPrompt />
                </StyleParamsCSSVariables>
              </PreviewProvider>
            </BiReadinessProvider>
          </SiteInfoProvider>
        </WorkerContext.Provider>
      </ApolloProvider>
    </Direction>
  );
};
